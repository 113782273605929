

















































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { localize } from 'vee-validate';

import { appName } from '@/env';
import {
  commitSetDashboardShowDrawer,
  readDashboardShowDrawer,
  commitSetDashboardMiniDrawer,
  readDashboardMiniDrawer,
  readHasAdminAccess,
  commitSetLang,
  readConfig,
  readHasUserAccess,
} from '@/store/main/accessors';
import { dispatchCheckLoggedIn } from '@/store/main/accessors';

const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/profile');
  } else {
    next();
  }
};

@Component
export default class Main extends Vue {
  public someKey: number = 0;
  public appName = appName;
  public newsletterItems = [
    {
      title: 'management',
      icon: 'table_chart',
      to: { name: 'main-newsletter-all' },
    },
    { title: 'run', icon: 'send', to: { name: 'main-newsletter-send' } },
  ];
  public seqSendsItems = [{ title: 'seqSends', icon: 'table_chart', to: { name: 'main-seq-sends-all' } }];
  public rateLimitsItems = [
    { title: 'management', icon: 'table_chart', to: { name: 'main-rate-limits-all' } },
    { title: 'create', icon: 'add', to: { name: 'main-rate-limits-create' } },
  ];

  public async mounted() {
    await this.login();
  }

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(this.$store, !readDashboardShowDrawer(this.$store));
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(this.$store, !readDashboardMiniDrawer(this.$store));
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public get hasSeqSendsAccess() {
    return this.hasAdminAccess || readHasUserAccess(this.$store);
  }

  public async login() {
    await dispatchCheckLoggedIn(this.$store);
  }

  public async logout() {
    (this as any).$keycloak.logout();
  }

  public async changeLocale() {
    this.$vuetify.lang.current = this.$vuetify.lang.current === 'ru' ? 'en' : 'ru';
    localStorage.lang = this.$vuetify.lang.current;
    commitSetLang(this.$store, this.$vuetify.lang.current);
    localize(this.$vuetify.lang.current);
    this.someKey += 1;
  }

  public get messagesStatsUrl() {
    return readConfig(this.$store).messagesStatsUrl;
  }

  public get authApiUrl() {
    return readConfig(this.$store).authApiUrl;
  }

  public get templatesApiUrl() {
    return readConfig(this.$store).templatesApiUrl;
  }
}
